import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import DirectionsCar from "@material-ui/icons/DirectionsCar";
import Business from "@material-ui/icons/Business";
import Memory from "@material-ui/icons/Memory";
import Eco from "@material-ui/icons/Eco";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={6} md={3}>
              <InfoArea
                description="Easy access to Junction 34 of the M4 motorway"
                icon={DirectionsCar}
                iconColor="rose"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <InfoArea
                description="best in class specification regionally"
                icon={Business}
                iconColor="rose"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <InfoArea
                description="SIGNIFICANT blue chip occupiers in neighbouring developments
                "
                icon={Memory}
                iconColor="rose"
                vertical
              />
            </GridItem>
            <GridItem xs={12} sm={6} md={3}>
              <InfoArea
                description="Our new GWYRDD build initiative to reduce both costs and carbon emissions
                "
                icon={Eco}
                iconColor="rose"
                vertical
              />
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
