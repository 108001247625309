import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import ButtonESG from "components/CustomButtons/ButtonESG.jsx";
import ButtonWWD from "components/CustomButtons/ButtonWWD.jsx";
import ButtonColliery from "components/CustomButtons/ButtonColliery.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";
import esgimg from "../../assets/img/ESGHeader.jpg";
import wwd from "../../assets/img/wwd.jpg";
import innerrender from "../../assets/img/InnerRender.jpg";
import Briscombe from "../../assets/img/GreggsBuilding.jpg";
import collieryimg from "../../assets/img/HomeHeader.jpg";
import image from "../../assets/img/footimg.png";
import colliery from "../../assets/img/colliery.png";

import developmentsStyle from "assets/jss/material-kit-react/views/developmentsPage.jsx";

class DevelopmentsPage extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/Overhead.jpg")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  Our developments
                  </div>
                  <div className={classes.subtitle}>
                  Ongoing development projects

                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}
          <GridContainer>
          <GridItem xs={12} sm={12} md={6}>    
          <div className={classes.imagewrapteam}>
          <img alt="..." src={collieryimg} className={classes.homeimage} />
          </div>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}> 
          <div className={classes.preheadingteam}>
          Colliery <br/><br/>Business Park
                  </div> 
          <div className={classes.listwrap}>
                <ul className={classes.ulist}>
                  <li>Llantrisant, Cardiff </li>
                  <li>Units available from 1,250 sqft to 30,000 sqft</li>
                  <li>Build to suits available</li>
                </ul>
                </div>
                </GridItem>
          <GridItem xs={12} sm={12} md={12}>
                 
                <div className={classes.parawrap}>
                <p className={classes.paratextteam}>
                Colliery Business Park is a new 71,000 sqft development of industrial, warehousing and urban logistics units in the Llantrisant area. The launch of Colliery Business Park sets a new level in quality of design and specification in Wales by meeting the requirements of the modern business both regionally and nationally. Our flexible approach and design offers connectivity and leverages the Park’s accessible location.
                <br/>
               

                
                </p>
              </div>
                
            </GridItem>
            </GridContainer>

            </div>  
          </div>

          <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          {/* <ProductSection /> */}

            </div>  
          </div>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrapfoot}>
          {/* <ProductSection /> */}

          <div className={classes.bgimg}       style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center"
          }}>
                    <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brandfooter}>
                  <div className={classes.footerimagewrap}>
          <img alt="..." src={colliery} className={classes.colliery} />
          </div>
                <ButtonColliery/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
          </div>

            </div>  
          </div>

        <Footer />
      </div>
    );
  }
}

export default withStyles(developmentsStyle)(DevelopmentsPage);